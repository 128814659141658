export const issueToken = async (mail, password) => {
    try {
        const baseUrl = `${process.env.REACT_APP_BASE_URL}/`;
        const url = `${baseUrl}${process.env.REACT_APP_API_ENDPOINT_ISSUE_TOKEN}`
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({
                mail: mail,
                password: password
            }),
            credentials: 'include'
        });

        if (response.ok) {
            const data = await response.json();
            return { success: true, userId: data.userId, token: data.token };
        } else if (response.status === 401) {
            const errorMessage = await response.json();
            return { success: false, message: errorMessage.error };
        } else {
            throw new Error(`[Status code: ${response.status}] トークンの発行に失敗しました`);
        }
    } catch (error) {
        console.error('An error occurred while issuing a token', error);
        throw error;
    }
};