import styles from '../css/CommonLayout.module.css';

const CommonLayout = ({ header, content }) => {
    return (
        <>
            <div className={styles.Header}>
                {header}
            </div>
            <div className={styles.Content}>
                {content}
            </div>
        </>
    )
}

export default CommonLayout;
