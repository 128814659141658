import React, { useState, useEffect } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import styles from '../css/InviteCodeManagement.module.css';
import { FaRegCopy, FaTrash } from 'react-icons/fa';
import { insertInviteCode, selectInviteCodeHistory, deleteInviteCode } from '../api/inviteCode';
import NotificationModal from './NotificationModal';
import CommonLayout from './CommonLayout';
const InviteCodeManagement = () => {
    const navigate = useNavigate();
    const { memberTypeList } = useOutletContext();
    const [selectedMemberTypeCode, setSelectedMemberTypeCode] = useState('01');
    const [expiryDate, setExpiryDate] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [issueCount, setIssueCount] = useState('');
    const [notificationMessage, setNotificationMessage] = useState('');
    const [notificationType, setNotificationType] = useState('');
    const [isNotificationOpen, setIsNotificationOpen] = useState(false);
    const [inviteCodeHistory, setInviteCodeHistory] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false); // 初期表示でモーダルを開く

    useEffect(() => {
        const storedNotification = localStorage.getItem('notificationMessage');
        if (storedNotification) {
            setNotificationMessage(storedNotification);
            setIsNotificationOpen(true);
            localStorage.removeItem('notificationMessage');
        }
    }, []);

    useEffect(() => {
        if (memberTypeList?.length > 0) {
            setSelectedMemberTypeCode(memberTypeList[0]?.memberTypeCode);
        }
    }, [memberTypeList]);

    useEffect(() => {
        const getInviteCodeHistory = async () => {
            const response = await selectInviteCodeHistory(navigate);
            if (response) {
                setInviteCodeHistory(response);
            }
        };
        getInviteCodeHistory();
    }, [navigate]);

    const formatDateString = (dateString) => {
        const [year, month, day] = dateString.split('/');
        return new Date(year, month - 1, day, 23, 59, 59);
    };

    const isExpired = (expiryDate) => {
        const expiry = formatDateString(expiryDate);
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return expiry < today;
    };

    const handleGenerateCode = async (e) => {
        e.preventDefault();
        if (!issueCount && !expiryDate) {
            setErrorMessage('発行件数と有効期限を入力してください。');
            return;
        } else if (!issueCount) {
            setErrorMessage('発行件数を入力してください。');
            return;
        } else if (!expiryDate) {
            setErrorMessage('有効期限を入力してください。');
            return;
        }
        const response = await insertInviteCode(navigate, selectedMemberTypeCode, issueCount, expiryDate);
        if (response) {
            setNotificationMessage(response.message);
            setNotificationType(response.success ? 'success' : 'error');
            setIsNotificationOpen(true);

            if (response.success) {
                localStorage.setItem('notificationMessage', response.message);
                window.location.reload();
            }
        }
    };

    const handleCopyToClipboard = (inviteCode) => {
        navigator.clipboard.writeText(inviteCode)
            .then(() => {
                setNotificationMessage('招待コードをコピーしました。');
                setNotificationType('success');
                setIsNotificationOpen(true);
            })
            .catch(err => {
                console.error('コピーに失敗しました: ', err);
            });
    };

    const handleDeleteCode = async (inviteCode) => {
        try {
            const response = await deleteInviteCode(navigate, inviteCode);
            if (response > 0) {
                setNotificationMessage('招待コードを削除しました。');
                setNotificationType('success');
                setIsNotificationOpen(true);
                window.location.reload();
            } else {
                throw new Error('招待コードの削除に失敗しました。');
            }
        } catch (error) {
            setNotificationMessage(error.message || '招待コードの削除に失敗しました。');
            setNotificationType('error');
            setIsNotificationOpen(true);
        }
    };

    const header = (
        <div className={styles.Header}>
            <div className={styles.TitleContainer}>
                <h3>招待コード</h3>
                <p>招待コードを管理します。</p>
            </div>
            {/* モーダルを開くボタンを常時表示 */}
            <button className={styles.OpenModalButton} onClick={() => setIsModalOpen(true)}>
                招待コード生成
            </button>
        </div>
    )

    const content = (
        <>
            <div className={styles.ContentContainer}>
                {isModalOpen && (
                    <div className={styles.modalOverlay}>
                        <div className={styles.modalContent}>
                            <div className={styles.ModalHeader}>
                                <div className={styles.ModalTitle}>招待コード生成</div>
                                <div className={styles.ModalSubTitle}>招待コードを生成します。</div>
                            </div>
                            {errorMessage && (
                                <NotificationModal
                                    isOpen={true}
                                    message={errorMessage}
                                    onClose={() => setErrorMessage('')}
                                    type="error"
                                />
                            )}
                            <form className={styles.GenerateCodeContainer} onSubmit={handleGenerateCode}>
                                <div className={styles.Wrapper}>
                                    <span className={styles.Label}>会員区分</span>
                                    <select
                                        className={styles.valueInput}
                                        onChange={(e) => setSelectedMemberTypeCode(e.target.value)}
                                        value={selectedMemberTypeCode}
                                    >
                                        {memberTypeList?.length > 0 ? (
                                            memberTypeList?.map((memberType) => (
                                                <option key={memberType?.memberTypeCode} value={memberType?.memberTypeCode}>
                                                    {memberType?.memberTypeName}
                                                </option>
                                            ))
                                        ) : (
                                            <option>Loading...</option>
                                        )}
                                    </select>
                                </div>
                                <div className={styles.Wrapper}>
                                    <span className={styles.Label}>発行件数</span>
                                    <input
                                        type="number"
                                        className={styles.valueInput}
                                        min="1"
                                        onChange={(e) => setIssueCount(e.target.value)}
                                    />
                                </div>
                                <div className={styles.Wrapper}>
                                    <span className={styles.Label}>有効期限</span>
                                    <input
                                        type="date"
                                        className={styles.valueInput}
                                        onChange={(e) => setExpiryDate(e.target.value)}
                                    />
                                </div>
                                <div className={styles.Wrapper}>
                                    <button
                                        className={styles.GenerateCodeButton}
                                        type="submit"
                                    >
                                        生成
                                    </button>
                                </div>
                            </form>
                            <button className={styles.CloseModalButton} onClick={() => setIsModalOpen(false)}>閉じる</button>
                        </div>
                    </div>
                )}

                <div className={styles.InviteCodeCard}>
                    <div className={styles.ContentHeader}>
                        <div className={styles.ContentTitle}>招待コード一覧</div>
                    </div>
                    <div className={styles.ContentBody}>
                        <div className={styles.inviteCodeHeader}>
                            <span>招待コード</span>
                            <span>会員区分</span>
                            <span>生成日時</span>
                            <span>有効期限</span>
                            <span>使用状態</span>
                            <span>アクション</span>
                        </div>
                        <div className={styles.inviteCodeListScrollable}>
                            <div className={styles.inviteCodeContainer}>
                                {inviteCodeHistory?.length > 0 && inviteCodeHistory?.map((history, index) => (
                                    <div key={`${index}-${history?.inviteCode}`} className={styles.inviteCodeRow}>
                                        <span>{history.inviteCode}</span>
                                        <span>
                                            {memberTypeList?.find(memberType => memberType?.memberTypeCode === history?.memberTypeCode)?.memberTypeName}
                                        </span>
                                        <span>{history?.createDate}</span>
                                        <span>{history?.expiryDate} 23:59:59</span>
                                        <span className={`${styles.status} ${history?.status === '1' ? styles.usedText :
                                            isExpired(history?.expiryDate) ? styles.expiredText : styles.notUsedText}`}>
                                            {history?.status === '1' ? '使用済み' :
                                                isExpired(history?.expiryDate) ? '期限切れ' : '未使用'}
                                        </span>
                                        <span>
                                            <div className={styles.CopyButtonContainer}>
                                                <button className={`${styles.CopyButton} 
                                                                ${history?.status === '1' || isExpired(history?.expiryDate) ? styles.noUseButton : ''}`}
                                                    disabled={history?.status === '1' || isExpired(history?.expiryDate)}
                                                    onClick={() => handleCopyToClipboard(history?.inviteCode)} >
                                                    <FaRegCopy className={styles.ButtonIcon} size={14} />
                                                </button>
                                                <button className={styles.DeleteButton} onClick={() => handleDeleteCode(history?.inviteCode)}>
                                                    <FaTrash className={styles.ButtonIcon} size={14} />
                                                </button>
                                            </div>
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <NotificationModal
                isOpen={isNotificationOpen}
                message={notificationMessage}
                onClose={() => setIsNotificationOpen(false)}
                type={notificationType}
            />
        </>
    )

    return (
        <CommonLayout
            header={header}
            content={content}
        />
    );
};

export default InviteCodeManagement;
