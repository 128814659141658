import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import styles from '../css/CreateUser.module.css';
import { BiSolidError } from "react-icons/bi";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ja } from 'date-fns/locale';
import { registerLocale } from 'react-datepicker';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { createUser } from '../api/createUser';
import NotificationModal from './NotificationModal';
import { FaArrowsRotate } from "react-icons/fa6";
import { FiEyeOff, FiEye } from 'react-icons/fi';

const prefectures = [
    { name: '北海道' }, { name: '青森県' }, { name: '岩手県' }, { name: '宮城県' },
    { name: '秋田県' }, { name: '山形県' }, { name: '福島県' }, { name: '茨城県' },
    { name: '栃木県' }, { name: '群馬県' }, { name: '埼玉県' }, { name: '千葉県' },
    { name: '東京都' }, { name: '神奈川県' }, { name: '新潟県' }, { name: '富山県' },
    { name: '石川県' }, { name: '福井県' }, { name: '山梨県' }, { name: '長野県' },
    { name: '岐阜県' }, { name: '静岡県' }, { name: '愛知県' }, { name: '三重県' },
    { name: '滋賀県' }, { name: '京都府' }, { name: '大阪府' }, { name: '兵庫県' },
    { name: '奈良県' }, { name: '和歌山県' }, { name: '鳥取県' }, { name: '島根県' },
    { name: '岡山県' }, { name: '広島県' }, { name: '山口県' }, { name: '徳島県' },
    { name: '香川県' }, { name: '愛媛県' }, { name: '高知県' }, { name: '福岡県' },
    { name: '佐賀県' }, { name: '長崎県' }, { name: '熊本県' }, { name: '大分県' },
    { name: '宮崎県' }, { name: '鹿児島県' }, { name: '沖縄県' }
];

registerLocale('ja', ja);

function CreateUser({ initialErrorMessage }) {
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors, isValid }, getValues, watch, trigger, setValue } = useForm({
        mode: 'all',
        defaultValues: {
            mail: '',
            password: '',
            confirmPassword: '',
            lastName: 'テスト',
            firstName: '太郎',
            lastNameKana: 'テスト',
            firstNameKana: 'タロウ',
            nickname: 'テストアカウント',
            gender: 'M',
            birthdate: new Date(1990, 0, 1),
            prefecture: '東京都',
            phone: '03123456789',
        }
    })

    const [selectedDate, setSelectedDate] = useState(new Date(1990, 0, 1));
    const [errorMessage, setErrorMessage] = React.useState(initialErrorMessage || '');
    const [notificationMessage, setNotificationMessage] = React.useState('');
    const [isNotificationOpen, setIsNotificationOpen] = React.useState(false);
    const [notificationType, setNotificationType] = React.useState('');
    const [password, setPassword] = useState('');
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    useEffect(() => {
        const storedNotification = localStorage.getItem('notificationMessage');
        if (storedNotification) {
            setNotificationMessage(storedNotification);
            setIsNotificationOpen(true);
            localStorage.removeItem('notificationMessage');
        }
    }, []);

    const onSubmit = async (data) => {
        try {
            data.mail = data.mail + '@example.com';
            const response = await createUser(navigate, data);
            if (response) {
                setNotificationMessage(response.message);
                setNotificationType(response.success ? 'success' : 'error');
                setIsNotificationOpen(true);

                // 成功した場合はローカルストレージに通知メッセージを保存し、リロード
                if (response.success) {
                    localStorage.setItem('notificationMessage', response.message);
                    window.location.reload();
                }
            } else {
                setErrorMessage(response.message || 'システムエラーが発生しました。システム管理者にお問い合わせください。');
            }
        } catch (error) {
            setErrorMessage('システムエラーが発生しました。システム管理者にお問い合わせください。');
        }
    };

    useEffect(() => {
        const subscription = watch((value, { name }) => {
            if (name === 'password') {
                trigger('confirmPassword');
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, trigger]);

    useEffect(() => {
        if (selectedDate !== null) {
            setValue('birthdate', selectedDate.toISOString());
            trigger('birthdate');
        }
    }, [selectedDate, setValue, trigger]);

    const generatePassword = () => {
        const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
        const numbers = '0123456789';
        const specialChars = '!@#$%^&*-_';
        const allChars = letters + numbers + specialChars;

        let newPassword = '';
        // 少なくとも1文字、1数字、1記号を含むように生成
        newPassword += letters.charAt(Math.floor(Math.random() * letters.length));
        newPassword += numbers.charAt(Math.floor(Math.random() * numbers.length));
        newPassword += specialChars.charAt(Math.floor(Math.random() * specialChars.length));

        // パスワードの残りの桁数をランダムに生成
        for (let i = 3; i < 12; i++) {
            newPassword += allChars.charAt(Math.floor(Math.random() * allChars.length));
        }

        // シャッフル
        newPassword = newPassword.split('').sort(() => 0.5 - Math.random()).join('');

        setPassword(newPassword);
    };

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };

    return (
        <>
            <div className={styles.registrationForm}>
                <div className={styles.formContainer} onClick={(e) => e.stopPropagation()}>
                    <div className={styles.registrationFormHeader}>
                        <h2>会員登録</h2>
                        <span>新規アカウントを作成するには以下のフォームを入力してください</span>
                    </div>
                    {errorMessage && (
                        <div className={styles.errorMessage}>
                            <span className={styles.errorIcon}><BiSolidError size={25} color={'#ff0000'} /></span>
                            {errorMessage}
                        </div>
                    )}
                    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
                        <div className={`${styles.formGroup} ${styles.emailGroup}`}>
                            <label htmlFor="mail" className={styles.required}>メールアドレス</label>
                            <div className={styles.emailContainer}>
                                <input
                                    type="mail"
                                    id="mail"
                                    autoComplete="new-email"
                                    name="mail"
                                    className={`${styles.inputField} ${styles.emailField}`}
                                    {...register('mail', {
                                        required: 'メールアドレスを入力してください',
                                        maxLength: {
                                            value: 255,
                                            message: 'メールアドレスは255文字以内で入力してください'
                                        },
                                        pattern: {
                                            value: /^[a-zA-Z0-9._%+-]+$/,
                                            message: 'メールアドレスの形式が正しくありません'
                                        },
                                    })}
                                    placeholder="your-email"
                                />
                                <div className={styles.emailSuffix}>@example.com</div>
                            </div>
                            {errors.mail && <span className={styles.error}>{errors.mail.message}</span>}
                        </div>


                        <div className={styles.formRow}>
                            <div className={styles.formGroup}>
                                <label htmlFor="password" className={styles.required}>パスワード</label>
                                <div className={styles.PasswordInputContainer}>
                                    <input
                                        type={isPasswordVisible ? 'text' : 'password'}
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        className={styles.inputField}
                                        id="password" autoComplete="new-password" name="password"
                                        {...register('password', {
                                            required: 'パスワードを入力してください',
                                            minLength: {
                                                value: 8,
                                                message: 'パスワードは8文字以上で入力してください'
                                            },
                                            maxLength: {
                                                value: 64,
                                                message: 'パスワードは64文字以内で入力してください'
                                            },
                                            pattern: {
                                                value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*-_])[A-Za-z\d!@#$%^&*-_]{8,}$/,
                                                message: 'パスワードは英字、数字、記号(!@#$%^&*-_)をそれぞれ1文字以上含む必要があります'
                                            }
                                        })} />
                                    <button type="button" onClick={generatePassword} className={styles.GeneratePasswordButton}>
                                        <FaArrowsRotate />
                                    </button>
                                    <button type="button" onClick={togglePasswordVisibility} className={styles.ToggleVisibilityButton}>
                                        {isPasswordVisible ? <FiEyeOff /> : <FiEye />}
                                    </button>
                                </div>
                                <span className={styles.note}>使用できる記号:&nbsp;&nbsp;&nbsp;!@#$%^&*-_</span>
                                {errors.password && <span className={styles.error}>{errors.password.message}</span>}
                            </div>

                            <div className={styles.formGroup}>
                                <label htmlFor="confirmPassword" className={styles.required}>パスワード（確認）</label>
                                <input type="password" id="confirmPassword" autoComplete="new-off" name="confirmPassword" className={`${styles.inputField}`}
                                    {...register('confirmPassword', {
                                        required: 'パスワード（確認）を入力してください',
                                        validate: (value) => value === getValues('password') || 'パスワードが一致しません'
                                    })} />
                                {errors.confirmPassword && <span className={styles.error}>{errors.confirmPassword.message}</span>}
                            </div>
                        </div>

                        <div className={styles.formGroup}>
                            <label htmlFor="phone" className={styles.required}>電話番号</label>
                            <input type="tel" id="phone" name="phone" autoComplete="new-tel" className={`${styles.inputField} ${styles.phoneField}`}
                                {...register('phone', {
                                    required: '電話番号を入力してください',
                                    maxLength: {
                                        value: 15,
                                        message: '電話番号は15桁以内で入力してください'
                                    },
                                    pattern: {
                                        value: /^[0-9]+$/,
                                        message: '電話番号は半角数字で入力してください'
                                    },
                                })} />
                            <span className={styles.note}>半角数字のみ入力可能（ハイフンなし）</span>
                            {errors.phone && <span className={styles.error}>{errors.phone.message}</span>}
                        </div>

                        <div className={styles.formRow}>
                            <div className={styles.formGroup}>
                                <label htmlFor="lastName" className={styles.required}>姓</label>
                                <input type="text" id="lastName" name="lastName" className={`${styles.inputField}`}
                                    {...register('lastName', {
                                        required: '姓を入力してください',
                                        maxLength: {
                                            value: 64,
                                            message: '姓は64文字以内で入力してください'
                                        }
                                    })} />
                                {errors.lastName && <span className={styles.error}>{errors.lastName.message}</span>}
                            </div>

                            <div className={styles.formGroup}>
                                <label htmlFor="firstName" className={styles.required}>名</label>
                                <input type="text" id="firstName" name="firstName" className={`${styles.inputField}`}
                                    {...register('firstName',
                                        {
                                            required: '名を入力してください',
                                            maxLength: {
                                                value: 64,
                                                message: '名は64文字以内で入力してください'
                                            }
                                        })} />
                                {errors.firstName && <span className={styles.error}>{errors.firstName.message}</span>}
                            </div>
                        </div>

                        <div className={styles.formRow}>
                            <div className={styles.formGroup}>
                                <label htmlFor="lastNameKana" className={styles.required}>姓（カナ）</label>
                                <input type="text" id="lastNameKana" name="lastNameKana" className={`${styles.inputField}`}
                                    {...register('lastNameKana', {
                                        required: '姓（カナ）を入力してください',
                                        maxLength: {
                                            value: 64,
                                            message: '姓（カナ）は64文字以内で入力してください'
                                        },
                                        pattern: {
                                            value: /^[ァ-ヶー]+$/,
                                            message: '姓（カナ）はカタカナで入力してください'
                                        },
                                    })} />
                                {errors.lastNameKana && <span className={styles.error}>{errors.lastNameKana.message}</span>}
                            </div>
                            <div className={styles.formGroup}>
                                <label htmlFor="firstNameKana" className={styles.required}>名（カナ）</label>
                                <input type="text" id="firstNameKana" name="firstNameKana" className={`${styles.inputField}`}
                                    {...register('firstNameKana', {
                                        required: '名（カナ）を入力してください',
                                        maxLength: {
                                            value: 64,
                                            message: '名（カナ）は64文字以内で入力してください'
                                        },
                                        pattern: {
                                            value: /^[ァ-ヶー]+$/,
                                            message: '名（カナ）はカタカナで入力してください'
                                        },
                                    })} />
                                {errors.firstNameKana && <span className={styles.error}>{errors.firstNameKana.message}</span>}
                            </div>
                        </div>

                        <div className={styles.formGroup}>
                            <label htmlFor="nickname" className={styles.required}>ニックネーム</label>
                            <input type="text" id="nickname" name="nickname" className={`${styles.inputField} ${styles.nicknameField}`}
                                {...register('nickname', {
                                    required: 'ニックネームを入力してください',
                                    maxLength: {
                                        value: 50,
                                        message: 'ニックネームは50文字以内で入力してください'
                                    }
                                })} />
                            {errors.nickname && <span className={styles.error}>{errors.nickname.message}</span>}
                        </div>

                        <div className={styles.formRow}>
                            <div className={styles.formGroup}>
                                <label className={styles.required}>性別</label>
                                <div className={styles.gender}>
                                    <input type="radio" id="male" name="gender" value="M"
                                        {...register('gender', { required: '性別は必須です' })} />
                                    <label htmlFor="male">男性</label>
                                    <input type="radio" id="female" name="gender" value="F"
                                        {...register('gender')} />
                                    <label htmlFor="female">女性</label>
                                    <input type="radio" id="other" name="gender" value="O"
                                        {...register('gender')} />
                                    <label htmlFor="other">その他</label>
                                </div>
                                {errors.gender && <span className={styles.error}>{errors.gender.message}</span>}
                            </div>
                        </div>

                        <div className={styles.formGroup}>
                            <label htmlFor="birthdate" className={styles.required}>生年月日</label>
                            <div className={styles.datePickerContainer}>
                                <div className={styles.iconContainer}>
                                    <FaRegCalendarAlt className={styles.calendarIcon} />
                                    <div onBlur={() => trigger('birthdate')}>
                                        <DatePicker
                                            selected={selectedDate}
                                            onChange={(date) => {
                                                setSelectedDate(date);
                                                setValue('birthdate', date ? date.toISOString() : '');
                                            }}
                                            dateFormat="yyyy/MM/dd"
                                            locale="ja"
                                            placeholderText="生年月日を選択してください"
                                            showYearDropdown
                                            showMonthDropdown
                                            dropdownMode="select"
                                            yearDropdownItemNumber={100}
                                            scrollableYearDropdown
                                            maxDate={new Date()}
                                            openToDate={new Date(new Date().getFullYear() - 25, 0, 1)}
                                            className={styles.customDatepickerInput}
                                            renderCustomHeader={({
                                                date,
                                                changeYear,
                                                changeMonth,
                                                decreaseMonth,
                                                increaseMonth,
                                                prevMonthButtonDisabled,
                                                nextMonthButtonDisabled,
                                            }) => (
                                                <div className={styles.customHeader}>
                                                    <button
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            decreaseMonth();
                                                        }}
                                                        disabled={prevMonthButtonDisabled}
                                                        className={styles.navButton}
                                                    >
                                                        {"<"}
                                                    </button>
                                                    <select
                                                        value={date.getFullYear()}
                                                        onChange={({ target: { value } }) => changeYear(value)}
                                                        className={styles.yearDropdown}
                                                    >
                                                        {Array.from({ length: 200 }, (_, i) => (
                                                            <option key={i} value={date.getFullYear() - 100 + i}>
                                                                {date.getFullYear() - 100 + i}年
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <select
                                                        value={date.getMonth()}
                                                        onChange={({ target: { value } }) => changeMonth(value)}
                                                        className={styles.monthDropdown}
                                                    >
                                                        {Array.from({ length: 12 }, (_, i) => (
                                                            <option key={i} value={i}>
                                                                {i + 1}月
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <button
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            increaseMonth();
                                                        }}
                                                        disabled={nextMonthButtonDisabled}
                                                        className={styles.navButton}
                                                    >
                                                        {">"}
                                                    </button>
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                                <input
                                    type="hidden" id="birthdate" name="birthdate"
                                    {...register('birthdate', {
                                        required: '生年月日を入力してください'
                                    })}
                                />
                                {errors.birthdate && <span className={styles.error}>{errors.birthdate.message}</span>}
                            </div>
                        </div>

                        <div className={styles.formGroup}>
                            <label htmlFor="prefecture" className={styles.required}>都道府県</label>
                            <select id="prefecture" name="prefecture" className={styles.prefectureField}
                                {...register('prefecture', {
                                    required: '都道府県を選択してください'
                                })}>
                                <option value="">選択してください</option>
                                {prefectures.map((prefecture) => (
                                    <option key={prefecture.name} value={prefecture.name}>
                                        {prefecture.name}
                                    </option>
                                ))}
                            </select>
                            {errors.prefecture && <span className={styles.error}>{errors.prefecture.message}</span>}
                        </div>

                        <div className={styles.formGroup}>
                            <button type="submit" className={styles.submitButton} disabled={!isValid}>登録</button>
                        </div>
                    </form>
                </div>
            </div>
            <NotificationModal
                message={notificationMessage}
                isOpen={isNotificationOpen}
                onClose={() => setIsNotificationOpen(false)}
                type={notificationType}
            />
        </>
    );
}

export default CreateUser;
