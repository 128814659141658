import React from 'react';
import styles from '../css/ConfirmationModal.module.css';

const ConfirmationModal = ({ isOpen, onClose, onConfirm, user, updateFlag }) => {
    if (!isOpen) return null;

    return (
        <div className={styles.ModalOverlay}>
            <div className={`${styles.ModalContent} ${updateFlag === 0 ? '' : styles.DeleteModal}`}>
                <h4 className={styles.ModalTitle}>ユーザーを{updateFlag === 0 ? '退会させ' : '削除し'}ますか？</h4>
                <p className={styles.ModalMessage}>
                    <b className={styles.Recommendation}>{updateFlag === 0 ? '' : 'この操作は取り消せません。'}</b>
                    <b>{user.userLastName} {user.userFirstName}</b>
                    のアカウント{updateFlag === 0 ? 'を退会させます' : 'を完全に削除します'}。
                </p>
                <div className={styles.ButtonContainer}>
                    <button onClick={onConfirm} className={styles.ConfirmButton}>{updateFlag === 0 ? '退会させる' : '削除'}</button>
                    <button onClick={onClose} className={styles.CancelButton}>キャンセル</button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationModal; 