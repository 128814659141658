import { useState, useEffect, useRef } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import styles from '../css/EditSearchUser.module.css';
import formatDate from '../utils/DateUtils';
import { FaRegEdit, FaTrash, FaTimes } from "react-icons/fa";
import { HiUserMinus } from "react-icons/hi2";
import { MdCheckBoxOutlineBlank, MdCheckBox } from "react-icons/md";
import { updateUser, quitUser, deleteUser } from '../api/editUser';
import ConfirmationModal from './ConfirmationModal';
import NotificationModal from './NotificationModal'; // Import the notification modal

const EditSearchUser = ({ searchResultMessage, setOpenEditor, setSelectedUserDetail, selectedUser, setSelectedUser, handleScroll, searchResults }) => {
    const navigate = useNavigate();
    const { memberTypeList } = useOutletContext();
    // メンバータイプのドロップダウン
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    // 選択したメンバータイプのコード
    const [selectedMemberType, setSelectedMemberType] = useState({
        memberTypeCode: memberTypeList?.[0]?.memberTypeCode,
        memberTypeName: memberTypeList?.[0]?.memberTypeName
    });
    // 選択したメンバータイプ
    // const [choicedMemberType, setChoicedMemberType] = useState({
    // 一括編集を開くか否か
    const [openBulkEditor, setOpenBulkEditor] = useState(false);
    // 更新フラグ
    const [updateFlag, setUpdateFlag] = useState(null);
    // モーダルを開くか否か
    const [isModalOpen, setIsModalOpen] = useState(false);
    // 退会するユーザー
    const [userToQuit, setUserToQuit] = useState(null);
    // 削除するユーザー
    const [userToDelete, setUserToDelete] = useState(null);
    // 通知モーダル
    const [isNotificationOpen, setIsNotificationOpen] = useState(false); // State for notification
    const [notificationMessage, setNotificationMessage] = useState(''); // State for notification message
    // メンバータイプのドロップダウンのリファレンス
    const dropdownRef = useRef(null);
    /*----------------------------------- 個別編集用 -----------------------------------*/
    const handleEditUser = (result) => {
        setOpenEditor(true);
        setSelectedUserDetail(result);
    };

    /*----------------------------------- 一括編集用 -----------------------------------*/
    // 選択したユーザーをセット
    const handleSelectUser = (result) => {
        setSelectedUser((prev) => {
            return prev.includes(result) ? prev.filter((user) => user !== result) : [...prev, result];
        });
    };

    // 全選択
    const handleSelectAll = () => {
        setSelectedUser((prev) => {
            if (prev.length === searchResults.length) {
                return [];
            } else {
                return searchResults;
            }
        });
    };

    // メンバータイプを選択
    const handleSelectType = (e) => {
        setSelectedMemberType({
            memberTypeCode: e.target.value,
            memberTypeName: e.target.options[e.target.selectedIndex].text
        });
        setIsDropdownOpen(false);
    };

    // 選択されたユーザーへ変更を適用
    const handleBulkEdit = async () => {
        const modifiedSelectedUser = selectedUser.map((user) => {
            user.memberTypeCode = selectedMemberType.memberTypeCode;
            return user;
        });

        const response = await updateUser(navigate, modifiedSelectedUser);
        if (response) {
            setNotificationMessage(response.message); // Set the notification message
            setIsNotificationOpen(true); // Open the notification

            if (response.success) {
                localStorage.setItem('notificationMessage', response.message); // Store notification message in localStorage
                window.location.reload();
            }
        }
    };

    /*----------------------------------- 共通 -----------------------------------*/
    // メンバータイプのドロップダウンを開く
    // const handleDropdownToggle = (e) => {
    //     e.stopPropagation();
    //     setIsDropdownOpen(!isDropdownOpen);
    // };

    // メンバータイプのドロップダウンの外側をクリックした場合
    const handleClickOutside = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setIsDropdownOpen(false);
        }
    };

    // 退会するユーザーをセット
    const handleQuitUser = (result) => {
        setUpdateFlag(0);
        setUserToQuit(result);
        setIsModalOpen(true);
    };

    // 退会を実行する
    const confirmQuitUser = async (user) => {
        const response = await quitUser(navigate, user);
        if (response) {
            if (response.success) {
                setNotificationMessage(response.message); // 通知メッセージをセット
                setIsNotificationOpen(true); // 通知モーダルを開く
                setIsModalOpen(false);
                localStorage.setItem('notificationMessage', response.message); // 通知メッセージをローカルストレージに保存
                setUserToQuit(null);
                setUpdateFlag(null);
                window.location.reload();
            } else {
                setNotificationMessage(response.message); // 通知メッセージをセット
                setIsNotificationOpen(true); // 通知モーダルを開く
            }
        }
    };

    // 削除するユーザー
    const handleDeleteUser = (result) => {
        setUpdateFlag(1);
        setUserToDelete(result);
        setIsModalOpen(true);
    };

    // 削除を実行する
    const confirmDeleteUser = async (user) => {
        const response = await deleteUser(navigate, user);
        if (response) {
            if (response.success) {
                setNotificationMessage(response.message); // 通知メッセージをセット
                setIsNotificationOpen(true); // 通知モーダルを開く
                setIsModalOpen(false);
                localStorage.setItem('notificationMessage', response.message); // 通知メッセージをローカルストレージに保存
                setUserToDelete(null);
                setUpdateFlag(null);
                window.location.reload();
            } else {
                setNotificationMessage(response.message); // 通知メッセージをセット
                setIsNotificationOpen(true); // 通知モーダルを開く
            }
        }
    };

    /*----------------------------------- 初期ロード -----------------------------------*/
    // メンバータイプのドロップダウンの外側をクリックした場合
    useEffect(() => {
        if (isDropdownOpen) {
            document.addEventListener('click', handleClickOutside);
        } else {
            document.removeEventListener('click', handleClickOutside);
        }

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [isDropdownOpen]);

    return (
        <div className={styles.SearchContainer}>
            <button className={styles.BulkEditButton} onClick={() => setOpenBulkEditor(true)}>
                一括編集
            </button>
            {searchResultMessage &&
                <div className={styles.SearchResultMessage}>
                    {searchResultMessage}
                </div>
            }
            <div className={styles.SearchResultContainer}>
                {searchResults.length > 0 && (
                    <div className={styles.SearchResultTable}>
                        <div className={styles.SearchResultTableHead}>
                            <span className={`${styles.SearchResultTableHeadItem} ${styles.CheckIcon}`} onClick={handleSelectAll}>
                                {selectedUser.length === searchResults.length && searchResults.length > 0 ? <MdCheckBox size={20} className={styles.Icon} /> : <MdCheckBoxOutlineBlank size={20} className={styles.Icon} />}
                            </span>
                            <span className={`${styles.SearchResultTableHeadItem}`}>ユーザー名</span>
                            <span className={`${styles.SearchResultTableHeadItem}`}>メールアドレス</span>
                            <span className={`${styles.SearchResultTableHeadItem}`}>会員区分</span>
                            <span className={`${styles.SearchResultTableHeadItem}`}>登録日時</span>
                            <span className={`${styles.SearchResultTableHeadItem}`}>ステータス</span>
                            <span className={`${styles.SearchResultTableHeadItem}`}>最終更新日時</span>
                            <span className={`${styles.SearchResultTableHeadItem}`}>最終更新者</span>
                            <span className={`${styles.SearchResultTableHeadItem}`}>個別編集</span>
                        </div>
                        <div className={styles.ScrolableList} onScroll={handleScroll}>
                            {searchResults.map((result, index) => (
                                <div
                                    key={`${result.userId}-${result.lastName}-${result.firstName}-${result.mail}-${index}`}
                                    className={styles.SearchResultTableBodyRow}
                                >
                                    <span className={`${styles.SearchResultTableBodyItem} ${styles.CheckIcon}`}>
                                        {selectedUser.includes(result) ? <MdCheckBox onClick={() => handleSelectUser(result)} size={20} className={styles.Icon} /> : <MdCheckBoxOutlineBlank onClick={() => handleSelectUser(result)} size={20} className={styles.Icon} />}
                                    </span>
                                    <span className={`${styles.SearchResultTableBodyItem} ${styles.UserName}`}>{result.userLastName} {result.userFirstName}</span>
                                    <span className={`${styles.SearchResultTableBodyItem} ${styles.Mail}`}>{result.mail}</span>
                                    <span className={`${styles.SearchResultTableBodyItem} ${styles.MemberType}`}>{result.memberTypeName}</span>
                                    <span className={`${styles.SearchResultTableBodyItem} ${styles.CreateDate}`}>{formatDate(result.createDate)}</span>
                                    <span className={`${styles.SearchResultTableBodyItem}`}>
                                        <div className={`${result.deleteFlg === '0' ? styles.StatusText : styles.DeleteStatusText}`}>
                                            {result.deleteFlg === '0' ? '会員' : '退会済み'}
                                        </div>
                                    </span>
                                    <span className={`${styles.SearchResultTableBodyItem} ${styles.UpdateDate}`}>{result.updateDate ? formatDate(result.updateDate) : 'ー'}</span>
                                    <span className={`${styles.SearchResultTableBodyItem} ${styles.UpdateUser}`}>{result.updaterLastName ? `${result.updaterLastName} ${result.updaterFirstName}` : 'ー'}</span>
                                    <span className={`${styles.SearchResultTableBodyItem} ${styles.Edit}`}>
                                        <button className={`${styles.EditButton} ${result.deleteFlg === '1' ? styles.DisabledButton : ''}`}
                                            disabled={result.deleteFlg === '1'}
                                            onClick={() => handleEditUser(result)}
                                        >
                                            <FaRegEdit className={styles.ButtonIcon} />
                                        </button>
                                        <button className={`${styles.QuitButton} ${result.deleteFlg === '1' ? styles.DisabledButton : ''}`}
                                            disabled={result.deleteFlg === '1'}
                                            onClick={() => handleQuitUser(result)}
                                        >
                                            <HiUserMinus className={styles.ButtonIcon} />
                                        </button>
                                        <button className={styles.DeleteButton} onClick={() => handleDeleteUser(result)}>
                                            <FaTrash className={styles.ButtonIcon} />
                                        </button>
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
            {openBulkEditor && (
                <div className={styles.BulkEditArea}>
                    <div className={styles.BulkEditContainer}>
                        <div className={styles.BulkEditCard}>
                            <div className={styles.closeButton} onClick={() => setOpenBulkEditor(false)}>
                                <FaTimes size={20} />
                            </div>
                            {selectedUser.length > 0 ? (
                                <>
                                    <div className={styles.BulkEditMessage}>
                                        <h2 className={styles.BulkEditMessageTitle}>
                                            一括編集
                                        </h2>
                                        <p className={styles.BulkEditMessageText}>
                                            {selectedUser.length}件のユーザーを一括編集します。
                                        </p>
                                    </div>
                                    <select className={styles.BulkSelectMemberType} onChange={handleSelectType}>
                                        {memberTypeList?.map((type) => (
                                            <option key={type.memberTypeCode} value={type.memberTypeCode}>
                                                {type.memberTypeName}
                                            </option>
                                        ))}
                                    </select>
                                    <button className={styles.BulkApplyButton} onClick={handleBulkEdit}>
                                        適用
                                    </button>
                                </>
                            ) : (
                                <div className={styles.BulkEditMessage}>
                                    ユーザーを選択してください。
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
            {/* 退会or削除モーダル */}
            {updateFlag !== null && (
                <ConfirmationModal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    onConfirm={updateFlag === 0 ?
                        () => {
                            confirmQuitUser(userToQuit);
                        } :
                        () => {
                            confirmDeleteUser(userToDelete);
                        }
                    }
                    user={updateFlag === 0 ?
                        userToQuit :
                        userToDelete
                    }
                    updateFlag={updateFlag}
                />
            )}
            {/* 通知モーダル */}
            <NotificationModal
                isOpen={isNotificationOpen}
                message={notificationMessage}
                onClose={() => setIsNotificationOpen(false)}
            />
        </div>
    );
};

export default EditSearchUser;
