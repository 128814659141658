import React from 'react';
import styles from '../css/ResetPasswordModal.module.css';
import NotificationModal from './NotificationModal';

const ResetPasswordModal = ({ isOpen, onClose, onConfirm, user, newPassword, setNewPassword, setNotificationMessage, setNotificationType, setIsNotificationOpen, isNotificationOpen }) => {
    if (!isOpen) return null;

    const copyPassword = () => {
        navigator.clipboard.writeText(newPassword)
            .then(() => {
                setNotificationMessage('パスワードをコピーしました。');
                setNotificationType('success');
                setIsNotificationOpen(true);
            })
            .catch(err => {
                console.error('コピーに失敗しました: ', err);
            });
    };

    return (
        <>
            <div className={styles.ModalOverlay}>
                <div className={styles.ModalContent}>
                    {newPassword === '' ? (
                        <>
                            <h4 className={styles.ModalMessage}>パスワードをリセットしますか？</h4>
                            <div className={styles.ModalMessage}>
                                <b className={styles.UserName}>{user.userLastName} {user.userFirstName}</b>
                                <div>のパスワードをリセットします。</div>
                            </div>
                            <div className={styles.ButtonContainer}>
                                <button className={styles.ConfirmButton} onClick={onConfirm}>リセット</button>
                                <button className={styles.CancelButton} onClick={onClose}>キャンセル</button>
                            </div>
                        </>
                    ) : (
                        <>
                            <h4 className={styles.ModalMessage}>パスワードをリセットしました。</h4>
                            <div className={styles.ModalMessage}>
                                <div className={styles.NewPassword}>新しいパスワード</div>
                                <input type="text" value={newPassword} readOnly className={styles.NewPasswordInput} />
                                <div className={styles.NewPassword}>1度しか表示されません。</div>
                            </div>
                            <div className={styles.ButtonContainer}>
                                <button className={styles.CopyButton} onClick={copyPassword}>コピー</button>
                                <button className={styles.CloseButton} onClick={() => {
                                    setNewPassword('');
                                    onClose();
                                }}>閉じる</button>
                            </div>
                        </>
                    )}
                </div>
            </div>
            <NotificationModal
                isOpen={isNotificationOpen}
                onClose={() => setIsNotificationOpen(false)}
                message={"コピーしました"}
                type={'success'}
            />
        </>
    );
};

export default ResetPasswordModal;
