import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../css/EditUser.module.css';
import EditSearchUser from './EditSearchUser';
import { updateUser } from '../api/editUser';
import NotificationModal from './NotificationModal';
import formatDateString from '../utils/DateUtils';
import { FaTimes } from 'react-icons/fa';
import ResetPasswordModal from './ResetPasswordModal';
import { resetPassword, editSearchUser } from '../api/editUser';
import { useOutletContext } from 'react-router-dom';
import CommonLayout from './CommonLayout';

const EditUser = () => {
    const navigate = useNavigate();
    const { memberTypeList } = useOutletContext();
    // 「全て」を含むメンバータイプのリスト
    const [modifiedMemberTypeList, setModifiedMemberTypeList] = useState([]);
    //検索結果のメッセージ
    const [searchResultMessage, setSearchResultMessage] = useState('');
    //編集画面を開くか否か
    const [openEditor, setOpenEditor] = useState(false);
    //選択したユーザーの情報
    const [selectedUserDetail, setSelectedUserDetail] = useState(null);
    //メンバータイプ
    const [memberTypeCode, setMemberTypeCode] = useState('');
    //ユーザー情報
    const [lastName, setLastName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastNameKana, setLastNameKana] = useState('');
    const [firstNameKana, setFirstNameKana] = useState('');
    const [mail, setMail] = useState('');
    //選択したユーザー
    const [selectedUser, setSelectedUser] = useState([]);
    //通知モーダル
    const [isNotificationOpen, setIsNotificationOpen] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [notificationType, setNotificationType] = useState('');
    //メンバータイプのドロップダウン
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    //パスワードリセットモーダル
    const [isResetPasswordOpen, setIsResetPasswordOpen] = useState(false);
    // ページ番号
    const [page, setPage] = useState(1);
    // ローディング中か否か
    const [loading, setLoading] = useState(false);
    // 検索ワード
    const [searchWord, setSearchWord] = useState('');
    // 選択したメンバータイプのコード
    const [selectedMemberTypeCode, setSelectedMemberTypeCode] = useState('00');
    // 検索結果
    const [searchResults, setSearchResults] = useState([]);
    // 新しいパスワード
    const [newPassword, setNewPassword] = useState('');


    // ローカルストレージから取得する系
    useEffect(() => {
        // ローカルストレージに通知メッセージがある場合は表示
        const storedNotification = localStorage.getItem('notificationMessage');
        if (storedNotification) {
            setNotificationMessage(storedNotification);
            setIsNotificationOpen(true);
            localStorage.removeItem('notificationMessage'); // 使用後は削除
        }
    }, []);

    const handleIndividSubmit = async (e) => {
        e.preventDefault();

        // 個別編集の場合は長さ1の配列を渡す
        //一括編集の場合は長さnの配列を渡し、同じapiを用いるため

        const userData = [{
            id: selectedUserDetail.id,
            userLastName: lastName,
            userFirstName: firstName,
            userLastNameKana: lastNameKana,
            userFirstNameKana: firstNameKana,
            mail,
            memberTypeCode
        }];

        // -------入力値のバリデーションチェック-------

        // 未入力の項目があるかチェック
        if (userData.some(user =>
            user.lastName === '' ||
            user.firstName === '' ||
            user.lastNameKana === '' ||
            user.firstNameKana === '' ||
            user.mail === ''
        )) {
            setNotificationMessage('未入力の項目があります');
            setNotificationType('error');
            setIsNotificationOpen(true);
            return;
        }

        // 全角カタカナ、全角ひらがな、漢字のみ許可（全角数字は弾く）
        const fullWidthRegex = /^[\u4E00-\u9FFF\u30A0-\u30FF\u3040-\u309F\uFF21-\uFF3A\uFF41-\uFF5A]+$/;
        if (!fullWidthRegex.test(lastName) || !fullWidthRegex.test(firstName)) {
            setNotificationMessage('苗字と名前は全角文字で入力してください');
            setNotificationType('error');
            setIsNotificationOpen(true);
            return;
        }

        // 名前のカナに半角が含まれていないかチェック
        const noHalfWidthKanaRegex = /^[\u30A0-\u30FF]+$/;
        if (!noHalfWidthKanaRegex.test(lastNameKana) || !noHalfWidthKanaRegex.test(firstNameKana)) {
            setNotificationMessage('カナは全角カナで入力してください');
            setNotificationType('error');
            setIsNotificationOpen(true);
            return;
        }

        // メールアドレスがメールアドレスの形式かチェック
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailRegex.test(mail)) {
            setNotificationMessage('メールアドレスの形式が不正です');
            setNotificationType('error');
            setIsNotificationOpen(true);
            return;
        }

        // 変更がない場合のバリデーションチェック
        if (userData.some(user => (
            user.lastName === selectedUserDetail.userLastName &&
            user.firstName === selectedUserDetail.userFirstName &&
            user.lastNameKana === selectedUserDetail.userLastNameKana &&
            user.firstNameKana === selectedUserDetail.userFirstNameKana &&
            user.mail === selectedUserDetail.mail &&
            user.memberTypeCode === selectedUserDetail.memberTypeCode
        ))) {
            setNotificationMessage('変更がありません');
            setNotificationType('error');
            setIsNotificationOpen(true);
            return;
        }

        // -----------------------------------------

        const response = await updateUser(navigate, userData);
        if (response) {
            setNotificationMessage(response.message);
            setNotificationType(response.success ? 'success' : 'error');
            setIsNotificationOpen(true);

            // 成功した場合はローカルストレージに通知メッセージを保存し、リロード
            if (response.success) {
                localStorage.setItem('notificationMessage', response.message);
                window.location.reload();
            }
        }
    };

    // 初期ロード
    useEffect(() => {
        const modifiedMemberType = [
            { memberTypeCode: '00', memberTypeName: '全て' },
            ...memberTypeList
        ];
        setModifiedMemberTypeList(modifiedMemberType);
        setSelectedMemberTypeCode(modifiedMemberType[0].memberTypeCode);

        // ローカルストレージから通知メッセージを取得
        const storedNotification = localStorage.getItem('notificationMessage');
        if (storedNotification) {
            setNotificationMessage(storedNotification);
            setIsNotificationOpen(true);
            localStorage.removeItem('notificationMessage'); // 使用後に削除
        }
    }, [setSearchResultMessage, memberTypeList]); // 初期ロード時にのみ実行

    useEffect(() => {
        // 選択したユーザーの情報をセット
        if (selectedUserDetail) {
            setLastName(selectedUserDetail.userLastName || '');
            setFirstName(selectedUserDetail.userFirstName || '');
            setLastNameKana(selectedUserDetail.userLastNameKana || '');
            setFirstNameKana(selectedUserDetail.userFirstNameKana || '');
            setMail(selectedUserDetail.mail || '');
            setMemberTypeCode(selectedUserDetail.memberTypeCode || '');
        }
    }, [selectedUserDetail]);

    // メンバータイプのドロップダウンを開く
    const handleDropdownToggle = (e) => {
        e.stopPropagation(); // イベントの伝播を防ぐ
        e.preventDefault();  // デフォルトの動作を防ぐ
        setIsDropdownOpen(!isDropdownOpen);
    };

    // メンバータイプを選択
    const handleSelectType = (type, e) => {
        e.stopPropagation(); // イベントの伝播を防ぐ
        e.preventDefault();  // デフォルトの動作を防ぐ
        setMemberTypeCode(type.memberTypeCode);
        setIsDropdownOpen(false);
    };

    // パスワードリセット
    const handleResetPassword = async (user) => {
        setOpenEditor(false);
        setIsResetPasswordOpen(true);
        setSelectedUserDetail(user);
    };

    const confirmResetPassword = async (user) => {
        const response = await resetPassword(navigate, user.id);
        if (response) {
            setNewPassword(response.message);
            setSelectedUserDetail(null);
        }
    };

    // スクロール
    const handleScroll = (e) => {
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - scrollTop <= clientHeight * 1.3) {
            loadMoreData();
        }
    };

    // 追加データの取得
    const loadMoreData = async () => {
        if (loading) return;
        setLoading(true);
        try {
            const nextPage = page + 1;
            const results = await editSearchUser(navigate, searchWord, selectedMemberTypeCode, nextPage);

            if (results.length > 0) {
                setSearchResults((prevResults) => [...prevResults, ...results]);
                setPage(nextPage);
            }
        } catch (error) {
            console.error('APIエラー：追加データの取得', error);
        } finally {
            setLoading(false);
        }
    };

    // 検索ワードの変更
    const handleInputChange = (e) => {
        setSearchWord(e.target.value);
    };

    // メンバータイプの選択
    const handleSelectChange = (e) => {
        setSelectedMemberTypeCode(e.target.value);
    };

    // 検索処理
    const handleSearch = useCallback(async (event) => {
        if (event) event.preventDefault(); // デフォルトのフォーム送信をキャンセル
        setPage(1);
        try {
            const results = await editSearchUser(navigate, searchWord, selectedMemberTypeCode, 1);
            console.log('results', results);
            if (results) {
                setSearchResults(results);
                setSearchResultMessage(results.length === 0 ? '該当するユーザーは見つかりませんでした。' : '');
            }
        } catch (error) {
            console.error('APIエラー：ユーザー検索', error);
        }
    }, [navigate, searchWord, selectedMemberTypeCode, setSearchResultMessage]);

    // 検索ワードまたはメンバータイプが変更された場合
    useEffect(() => {
        if (searchWord || selectedMemberTypeCode) {
            handleSearch();
        }
    }, [searchWord, selectedMemberTypeCode, handleSearch]);

    const header = (
        <div className={styles.Header}>
            <div className={styles.TitleContainer}>
                <h3>ユーザー情報編集</h3>
                <p>利用者の登録情報を編集できます</p>
            </div>
            <form className={styles.SearchForm} onSubmit={handleSearch}>
                <input
                    type="text"
                    placeholder="ユーザー名またはメールアドレスで検索..."
                    onChange={handleInputChange}
                    className={styles.SearchInput}
                />
                <select onChange={handleSelectChange} className={styles.SearchSelect}>
                    {modifiedMemberTypeList.map((type) => (
                        <option key={type.memberTypeCode} value={type.memberTypeCode}>
                            {type.memberTypeName}
                        </option>
                    ))}
                </select>
            </form>
        </div>
    )

    const content = (
        <>
            <EditSearchUser
                searchResultMessage={searchResultMessage}
                setSearchResultMessage={setSearchResultMessage}
                setOpenEditor={setOpenEditor}
                setSelectedUserDetail={setSelectedUserDetail}
                selectedUser={selectedUser}
                setSelectedUser={setSelectedUser}
                handleScroll={handleScroll}
                searchResults={searchResults}
            />
            {openEditor && (
                <div className={styles.EditArea}>
                    <div className={styles.EditContainer}>
                        <div className={styles.EditCard}>
                            <div className={styles.closeButton}>
                                <FaTimes size={20} onClick={() => setOpenEditor(false)} cursor="pointer" />
                            </div>
                            <form onSubmit={handleIndividSubmit} className={styles.EditForm}>
                                <div className={styles.ModalBody}>
                                    <div className={styles.NameZone}>
                                        <div className={styles.ZoneItem}>
                                            <label>姓</label>
                                            <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                        </div>
                                        <div className={styles.ZoneItem}>
                                            <label>名</label>
                                            <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className={styles.NameKanaZone}>
                                        <div className={styles.ZoneItem}>
                                            <label>姓(カナ)</label>
                                            <input type="text" value={lastNameKana} onChange={(e) => setLastNameKana(e.target.value)} />
                                        </div>
                                        <div className={styles.ZoneItem}>
                                            <label>名(カナ)</label>
                                            <input type="text" value={firstNameKana} onChange={(e) => setFirstNameKana(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className={styles.ZoneItem}>
                                        <label>メールアドレス</label>
                                        <input type="text" value={mail} onChange={(e) => setMail(e.target.value)} />
                                    </div>
                                    <div className={styles.ZoneItem}>
                                        <label>会員区分</label>
                                        <div className={styles.DropdownContainer}>
                                            <button onClick={handleDropdownToggle} className={styles.DropdownButton}>
                                                {memberTypeList?.find(type => type.memberTypeCode === memberTypeCode)?.memberTypeName || '選択してください'} ▾
                                            </button>
                                            {isDropdownOpen && (
                                                <div className={`${styles.DropdownMenu} ${styles.DropdownMenuOpen}`}>
                                                    {memberTypeList?.map((type) => (
                                                        <div
                                                            key={type.memberTypeCode}
                                                            className={styles.DropdownMenuItem}
                                                            onClick={(e) => handleSelectType(type, e)}
                                                        >
                                                            {type.memberTypeName}
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className={styles.ZoneItem}>
                                        <label>作成日時</label>
                                        <div>{formatDateString(selectedUserDetail?.createDate)}</div>
                                    </div>
                                    <div className={styles.ButtonZone}>
                                        <button className={styles.resetPasswordButton} onClick={() => handleResetPassword(selectedUserDetail)}>パスワードリセット</button>
                                        <button type="submit">更新</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}
            <NotificationModal
                isOpen={isNotificationOpen}
                message={notificationMessage}
                onClose={() => setIsNotificationOpen(false)}
                type={notificationType}
            />
            {isResetPasswordOpen && (
                <ResetPasswordModal
                    isOpen={isResetPasswordOpen}
                    onClose={() => setIsResetPasswordOpen(false)}
                    onConfirm={() => confirmResetPassword(selectedUserDetail)}
                    user={selectedUserDetail}
                    newPassword={newPassword}
                    setNewPassword={setNewPassword}
                    setNotificationMessage={setNotificationMessage}
                    setNotificationType={setNotificationType}
                    setIsNotificationOpen={setIsNotificationOpen}
                    isNotificationOpen={isNotificationOpen}
                />
            )}
        </>
    )

    return (
        <CommonLayout
            header={header}
            content={content}
        />
    );
};

export default EditUser;
