import styles from '../css/UserPage.module.css';
import { useState } from 'react';
import UsersProgressCard from './UsersProgressCard';
import UsersQuestionHistoryCard from './UsersQuestionHistoryCard';
import { FaListAlt, FaTimes } from 'react-icons/fa';
import { TbListSearch } from 'react-icons/tb';
import { LuArrowLeftToLine, LuArrowRightToLine } from 'react-icons/lu';


const UserPage = ({ data, selectedChannel, setSelectedChannel, setOpenUserPage }) => {
    const [toggleButton, setToggleButton] = useState(true);

    const toggleButtonClick = () => {
        setToggleButton(!toggleButton);
    };

    //教材リスト
    const [partsList, setPartsList] = useState([]);

    return (
        <>
            <div className={styles.myPage}>
                <div className={styles.Header}>
                    <div className={styles.HeaderText}>
                        <h1> {data ? `${data.userLastName} ${data.userFirstName}` : 'データ取得エラー'}の詳細</h1>
                    </div>
                    <div className={styles.closeButton} onClick={() => setOpenUserPage(false)}>
                        <FaTimes size={20} />
                    </div>
                </div>
                <div className={styles.body}>
                    <div className={`${styles.sidePanel} ${toggleButton ? styles.open : ''}`}>
                        <div className={styles.sideButtonContainer}>
                            <p
                                className={`${styles.sideButton} ${selectedChannel === '0' ? styles.active : ''}`}
                                onClick={() => setSelectedChannel('0')}
                            >
                                <FaListAlt size={20} />
                                {toggleButton && <span>学習進捗</span>}
                            </p>
                            <p
                                className={`${styles.sideButton} ${selectedChannel === '1' ? styles.active : ''}`}
                                onClick={() => setSelectedChannel('1')}
                            >
                                <TbListSearch size={20} />
                                {toggleButton && <span>AI質問履歴</span>}
                            </p>
                            {/*いずれプロフィールも見れるようにするなら下記を追加*/}
                            {/* <p
                            className={`${styles.sideButton} ${activeTab === 'profile' ? styles.active : ''}`}
                            onClick={() => handleTabClick('profile')}
                        >
                            <RiProfileLine size={20} />
                            {toggleButton && <span>プロフィール</span>}
                        </p> */}
                        </div>
                        <div className={styles.toggleButton} onClick={toggleButtonClick}>
                            {toggleButton ?
                                <LuArrowLeftToLine size={20} />
                                :
                                <LuArrowRightToLine size={20} />
                            }
                        </div>
                    </div>
                    <div className={styles.mainPanel}>
                        {selectedChannel === '0' &&
                            <div className={styles.progress}>
                                {data ?
                                    <UsersProgressCard
                                        data={data}
                                        partsList={partsList}
                                        setPartsList={setPartsList}
                                    />
                                    : 'データ取得エラー'}
                            </div>
                        }
                        {selectedChannel === '1' ?
                            data ?
                            <UsersQuestionHistoryCard
                                data={data}
                                partsList={partsList}
                            />
                                : 'データ取得エラー'
                            : ''
                        }
                    </div>
                </div>
            </div >
        </>
    );
};

export default UserPage;