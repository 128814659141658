export const editSearchUser = async (navigate, searchWord, selectedMemberType, page) => {
    try {
        const baseUrl = `${process.env.REACT_APP_BASE_URL}/`;
        const searchResultsUrl = `${baseUrl}${process.env.REACT_APP_API_ENDPOINT_EDIT_SEARCH_USER}`;

        const response = await fetch(searchResultsUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({
                searchWord: searchWord,
                memberType: selectedMemberType,
                page: page
            }),
            credentials: 'include'
        });
        if (response.ok) {
            return await response.json();
        } else if (response.status === 401) {
            navigate('/login');
        } else {
            navigate('/error', { state: { statusCode: response.status, errorMessage: 'ユーザー検索に失敗しました' } });
        }
    } catch (error) {
        console.error('APIエラー：ユーザー検索', error);
        navigate('/error', { state: { statusCode: '', errorMessage: 'ユーザー検索に失敗しました' } });
    }
};



export const updateUser = async (navigate, userData) => {
    try {
        const baseUrl = `${process.env.REACT_APP_BASE_URL}/`;
        const editUserUrl = `${baseUrl}${process.env.REACT_APP_API_ENDPOINT_UPDATE_USER}`;
        const response = await fetch(editUserUrl, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userData),
            credentials: 'include'
        });
        if (response.ok) {
            return await response.json();
        } else if (response.status === 401) {
            navigate('/login');
        } else {
            throw new Error(`[Status code: ${response.status}] ユーザーの更新に失敗しました`);
        }

    } catch (error) {
        console.error('APIエラー：ユーザーの更新', error);
        navigate('/error', { state: { statusCode: '', errorMessage: 'ユーザーの更新に失敗しました' } });
    }
};

export const quitUser = async (navigate, user) => {
    try {
        const baseUrl = `${process.env.REACT_APP_BASE_URL}/`;
        const quitUserUrl = `${baseUrl}${process.env.REACT_APP_API_ENDPOINT_QUIT_USER}`;

        const response = await fetch(quitUserUrl, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(user),
            credentials: 'include'
        });
        if (response.ok) {
            return await response.json();
        } else if (response.status === 401) {
            navigate('/login');
        } else {
            throw new Error(`[Status code: ${response.status}] ユーザーの退会に失敗しました`);
        }

    } catch (error) {
        console.error('APIエラー：ユーザーの退会', error);
        navigate('/error', { state: { statusCode: '', errorMessage: 'ユーザーの退会に失敗しました' } });
    }
};

export const deleteUser = async (navigate, user) => {
    try {
        const baseUrl = `${process.env.REACT_APP_BASE_URL}/`;
        const deleteUserUrl = `${baseUrl}${process.env.REACT_APP_API_ENDPOINT_DELETE_USER}`;

        const response = await fetch(deleteUserUrl, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(user),
            credentials: 'include'
        });
        if (response.ok) {
            return await response.json();
        } else if (response.status === 401) {
            navigate('/login');
        } else {
            throw new Error(`[Status code: ${response.status}] ユーザーの削除に失敗しました`);
        }
    } catch (error) {
        console.error('APIエラー：ユーザーの削除', error);
        navigate('/error', { state: { statusCode: '', errorMessage: 'ユーザーの削除に失敗しました' } });
    }
};

export const resetPassword = async (navigate, userId) => {
    try {
        const baseUrl = `${process.env.REACT_APP_BASE_URL}/`;
        const resetPasswordUrl = `${baseUrl}${process.env.REACT_APP_API_ENDPOINT_RESET_PASSWORD}?userId=${userId}`;

        const response = await fetch(resetPasswordUrl, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include'
        });

        if (response.ok) {
            return await response.json();
        } else if (response.status === 401) {
            navigate('/login');
        } else {
            throw new Error(`[Status code: ${response.status}] パスワードのリセットに失敗しました`);
        }
    } catch (error) {
        console.error('APIエラー：パスワードのリセット', error);
        navigate('/error', { state: { statusCode: '', errorMessage: 'パスワードのリセットに失敗しました' } });
    }
}

