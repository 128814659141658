import React, { useState } from 'react';
import styles from '../css/Statistics.module.css';
import commonStyles from '../css/Common.module.css';
import StatisticsOverview from './Statistics_Overview';
import StatisticsCategory from './Statistics_Category';
import StatisticsUser from './Statistics_User';

const Statistics = () => {

    const [selectedChannel, setSelectedChannel] = useState(0);


    return (
        <div className={commonStyles.WholeContainer}>
            <div className={commonStyles.TitleContainer}>
                <div className={commonStyles.TitleText}>統計情報</div>
                <div className={commonStyles.subTitle}>利用者の統計情報が確認できます</div>
            </div>
            <div className={commonStyles.BodyContainer}>
                <div className={styles.ChartChannelContainer}>
                    <div className={styles.ChartChannelTitleContainer}>
                        <label className={`${styles.ChartChannelTitle} ${selectedChannel === 0 ? styles.Selected : ''}`} onClick={() => setSelectedChannel(0)}>
                            概要
                        </label>
                        <label className={`${styles.ChartChannelTitle} ${selectedChannel === 1 ? styles.Selected : ''}`} onClick={() => setSelectedChannel(1)}>
                            教材
                        </label>
                        <label className={`${styles.ChartChannelTitle} ${selectedChannel === 2 ? styles.Selected : ''}`} onClick={() => setSelectedChannel(2)}>
                            ユーザー
                        </label>
                    </div>
                </div>
                <div className={styles.ChartContainer}>
                    {selectedChannel === 0 && <StatisticsOverview />}
                    {selectedChannel === 1 && <StatisticsCategory />}
                    {selectedChannel === 2 && <StatisticsUser />}
                </div>
            </div>
        </div>
    );
};

export default Statistics;
