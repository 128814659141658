import styles from '../css/SideMenu.module.css';
import { Link } from 'react-router-dom';
import { RiUserSettingsFill, RiUserAddFill, RiUserSearchFill, RiMessageFill, RiFunctionFill } from 'react-icons/ri';
import { BsGraphUpArrow } from 'react-icons/bs';
import { LuArrowLeftToLine, LuArrowRightToLine } from 'react-icons/lu';

const SideMenu = ({ selectedMenu, setSelectedMenu, toggleButton, setToggleButton }) => {

    const handleClick = (index) => {
        setSelectedMenu(prevSelected => prevSelected === index ? prevSelected : index);
    };

    return (
        <div className={`${styles.SideMenuContainer} ${toggleButton ? styles.open : ''}`}>
            <div className={`${styles.EachMenuContainer} ${selectedMenu === 0 ? styles.Selected : ''}`} onClick={() => handleClick(0)}>
                <Link to="/adminPanel" className={styles.ButtonBack}>
                    <div className={styles.IconBox}>
                        <RiFunctionFill size={20} className={styles.Icon} />
                    </div>
                    <div className={styles.TextBox}>
                        <div className={styles.TextContainer}>Home</div>
                    </div>
                </Link>
            </div>
            <div className={`${styles.EachMenuContainer} ${selectedMenu === 1 ? styles.Selected : ''}`} onClick={() => handleClick(1)}>
                <Link to="user-detail" className={styles.ButtonBack}>
                    <div className={styles.IconBox}>
                        <RiUserSearchFill size={20} className={styles.Icon} />
                    </div>
                    <div className={styles.TextBox}>
                        <div className={styles.TextContainer}>学習進捗検索</div>
                    </div>
                </Link>
            </div>
            <div className={`${styles.EachMenuContainer} ${selectedMenu === 2 ? styles.Selected : ''}`} onClick={() => handleClick(2)}>
                <Link to="invite-codes" className={styles.ButtonBack}>
                    <div className={styles.IconBox}>
                        <RiUserAddFill size={20} className={styles.Icon} />
                    </div>
                    <div className={styles.TextBox}>
                        <div className={styles.TextContainer}>招待コード管理</div>
                    </div>
                </Link>
            </div>
            <div className={`${styles.EachMenuContainer} ${selectedMenu === 3 ? styles.Selected : ''}`} onClick={() => handleClick(3)}>
                <Link to="edit-user" className={styles.ButtonBack}>
                    <div className={styles.IconBox}>
                        <RiUserSettingsFill size={20} className={styles.Icon} />
                    </div>
                    <div className={styles.TextBox}>
                        <div className={styles.TextContainer}>ユーザー情報編集</div>
                    </div>
                </Link>
            </div>
            <div className={`${styles.EachMenuContainer} ${selectedMenu === 4 ? styles.Selected : ''}`} onClick={() => handleClick(4)}>
                <Link to="ai-questions" className={styles.ButtonBack}>
                    <div className={styles.IconBox}>
                        <RiMessageFill size={20} className={styles.Icon} />
                    </div>
                    <div className={styles.TextBox}>
                        <div className={styles.TextContainer}>AI質問履歴検索</div>
                    </div>
                </Link>
            </div>
            <div className={`${styles.EachMenuContainer} ${selectedMenu === 5 ? styles.Selected : ''}`} onClick={() => handleClick(5)}>
                <Link to="statistics" className={styles.ButtonBack}>
                    <div className={styles.IconBox}>
                        <BsGraphUpArrow size={20} className={styles.Icon} />
                    </div>
                    <div className={styles.TextBox}>
                        <div className={styles.TextContainer}>統計</div>
                    </div>
                </Link>
            </div>
            <div className={`${styles.EachMenuContainer} ${selectedMenu === 6 ? styles.Selected : ''}`} onClick={() => handleClick(6)}>
                <Link to="create-user" className={styles.ButtonBack}>
                    <div className={styles.IconBox}>
                        <RiUserAddFill size={20} className={styles.Icon} />
                    </div>
                    <div className={styles.TextBox}>
                        <div className={styles.TextContainer}>テストユーザー作成</div>
                    </div>
                </Link>
            </div>
            <div className={`${styles.toggleButton} ${toggleButton ? styles.open : ''}`} onClick={() => setToggleButton(!toggleButton)}>
                {toggleButton ?
                    <LuArrowLeftToLine size={30} />
                    :
                    <LuArrowRightToLine size={30} />
                }
            </div>
        </div>
    );
};

export default SideMenu;