import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../css/Statistics_User.module.css';
import { fetchPartData, fetchUserProgress, fetchPageCount } from '../api/statistics';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { BarLoader } from 'react-spinners';
import Pagenation from '../utils/Pagenation';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const StatisticsUser = () => {
    const navigate = useNavigate();
    const [partData, setPartData] = useState([]);
    const [selectedPart, setSelectedPart] = useState();
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [graphData, setGraphData] = useState({ labels: [], datasets: [{ data: [] }] });
    const [months, setMonths] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(null);  // 初期ページは1
    const [pageCount, setPageCount] = useState(1);

    useEffect(() => {
        const generateMonths = () => {
            const monthsArray = [];
            const currentDate = new Date();
            for (let i = 0; i < 12; i++) {
                const date = new Date(currentDate.getFullYear(), currentDate.getMonth() - i, 1);
                const year = date.getFullYear();
                const month = date.getMonth() + 1;
                const lastDate = new Date(year, month, 0).getDate();

                monthsArray.push({ year, month, name: `${year}年${month}月`, lastDate: lastDate });
            }

            setStartDate(`${monthsArray[0].year}-${monthsArray[0].month}-01 00:00:00`);
            setEndDate(`${monthsArray[0].year}-${monthsArray[0].month}-${monthsArray[0].lastDate} 23:59:59`);
            setMonths(monthsArray);
        };

        generateMonths();
    }, [setStartDate, setEndDate, setMonths, setPageCount, navigate]);

    useEffect(() => {
        const fetchPartDataAsync = async () => {
            try {
                setIsLoading(true);
                const partData = await fetchPartData(navigate);
                setPartData(partData);
                if (partData.length > 0) {
                    setSelectedPart(partData[0].id); // 初期選択を設定
                }
            } catch (error) {
                console.error('APIエラー：パートデータの取得', error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchPartDataAsync();
    }, [navigate]);

    const pageCountAPI = useCallback(async () => {

        try {
            const pageData = await fetchPageCount(
                navigate,
                startDate,
                endDate
            );
            setPageCount(pageData);
        } catch (error) {
            console.error('APIエラー：ページカウントの取得', error);
        }
    }, [navigate, startDate, endDate]);

    useEffect(() => {
        if (startDate && endDate) {
            pageCountAPI();
        }
    }, [pageCountAPI, startDate, endDate]);

    const searchUserProgress = useCallback(async () => {
        try {
            setIsLoading(true);
            const { userProgress = [], totalPages } = await fetchUserProgress(
                navigate,
                startDate,
                endDate,
                selectedPart,
                currentPage
            );
            const selectedPartData = partData.find((part) => part.id === Number(selectedPart));
            setGraphData({
                labels: userProgress.map((item) => `${item.lastName}${item.firstName}`),
                datasets: [{
                    label: selectedPartData ? `${selectedPartData.partTitle}の進捗率` : '進捗率',
                    data: userProgress.map((item) => item.progressRate),
                    backgroundColor: 'rgba(53, 162, 235, 0.5)'
                }]
            });
            setCurrentPage(1);
            setPageCount(totalPages); // totalPagesをpageCountに設定
        } catch (error) {
            console.error('APIエラー：ユーザー進捗の取得', error);
        } finally {
            setIsLoading(false);
        }
    }, [navigate, startDate, endDate, selectedPart, partData, currentPage]);

    useEffect(() => {
        if (selectedPart) {
            searchUserProgress();
        }
    }, [selectedPart, startDate, endDate, searchUserProgress, currentPage]);

    const handlePartChange = (partId) => {
        setSelectedPart(partId);
    };

    const handleStartDateChange = (e) => {
        const [year, month] = e.target.value.split(',');
        setStartDate(`${year}-${month}-01 00:00:00`);
    };

    const handleEndDateChange = (e) => {
        const [year, month, lastDay] = e.target.value.split(',');
        setEndDate(`${year}-${month}-${lastDay} 23:59:59`);
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            tooltip: {
                mode: 'index',
                intersect: false,
            },
        },
    };

    const handlePageChange = (page) => {
        if (page >= 1 && page <= Math.ceil(pageCount)) {
            setCurrentPage(page);
        }
    };

    return (
        <>
            <div className={styles.ChartContainer}>
                <div className={styles.SelecterContainer}>
                    <select
                        className={`${styles.startDateSelecter} ${styles.Select}`}
                        value={startDate}
                        onChange={handleStartDateChange}
                    >
                        {months.map((month) => (
                            <option
                                key={`start${month.year}${month.month}`}
                                value={`${month.year}-${month.month}-01 00:00:00`}
                            >
                                {month.name}
                            </option>
                        ))}
                    </select>
                    <div className={styles.to}>～</div>
                    <select
                        className={`${styles.endDateSelecter} ${styles.Select}`}
                        value={endDate}
                        onChange={handleEndDateChange}
                    >
                        {months.map((month) => (
                            <option
                                key={`end${month.year}${month.month}`}
                                value={`${month.year}-${month.month}-${month.lastDay} 23:59:59`}
                            >
                                {month.name}
                            </option>
                        ))}
                    </select>
                    <select
                        className={`${styles.partSelecter} ${styles.Select}`}
                        value={selectedPart}
                        onChange={(e) => handlePartChange(e.target.value)}
                    >
                        {partData?.map((part) => (
                            <option key={part.id} value={part.id}>{part.partTitle}</option>
                        ))}
                    </select>
                </div>
                {isLoading ? (
                    <div className={styles.LoadingContainer}>
                        <BarLoader color="#36d7b7" />
                        <div>Loading...</div>
                    </div>
                ) : graphData.labels.length > 0 ? (
                    <>
                        <Bar data={graphData} options={options} />
                        {currentPage !== null && (
                            <Pagenation
                                currentPage={currentPage}
                                totalPages={pageCount}  // pageCountを使用
                                onPageChange={handlePageChange}
                            />
                        )}
                    </>
                ) : (
                    <div>データがありません</div>
                )}
            </div>
        </>
    );
};

export default StatisticsUser;
